












import { Component, Vue } from "vue-property-decorator";
import {agreementApi} from "@/utils/api";
import headbox from "@/components/head.vue";

@Component({
  components: {
    headbox
  }
})
export default class policyModel extends Vue {
    msg?:string = "";
    type = "";
    title= "";

    created () {
        this.type = `${this.$route.query.type}`
         this.title = `${this.$route.query.title}`

    }
    mounted(){
    this.getAgeement(this.type)
        
    }
    getAgeement(type:string){
        agreementApi(type).then((res:any) => {
            // debugger
          this.msg = res.content;
        });
    }

}
