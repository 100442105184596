var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", { attrs: { title: _vm.title } }),
      _vm.msg
        ? _c("div", {
            staticClass: "policy",
            domProps: { innerHTML: _vm._s(_vm.msg) }
          })
        : _c(
            "div",
            { staticClass: "loadingView" },
            [_c("van-loading", { attrs: { size: "2rem", color: "#1989fa" } })],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }